
import { Component, Ref } from 'vue-property-decorator';
import api from '@/modules/core/core-api';
import { VForm } from '@/plugins/vuelidate';
import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';
import PwrVue from '@/components/PwrVue';
import { CoreInputValidators } from '@/modules/core/core-input-validators';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import OfferFilters from '@/views/Offers/OfferFilters/OfferFilters.vue';

@Component({
  components: { OfferFilters, PwrCardTitle, PwrCard, PwrBtn }
})
export default class ContactView extends PwrVue {
  @Ref('form') readonly form!: VForm;

  private valid = false;
  private topic = '';
  private email = '';
  private msg = '';
  private checkbox = false;

  private mounted(): void {
    this.resetAfkTimer();
  }

  private ruleTopic = [
    (v: any) => CoreInputValidators.required(v, this.$t('views.contact.form.fieldTopic') as string)
  ];

  private ruleEmail() {
    if (!this.email) {
      return CoreInputValidators.required(
        this.email,
        this.$t('views.contact.form.fieldEmail') as string
      );
    }
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.email)) {
      return this.$t('validation.invalid', {
        field: this.$t('views.contact.validation.email'),
        subject: this.$t('views.contact.validation.emailSubject')
      });
    }
    return true;
  }

  private ruleMsg = [
    (v: any) => CoreInputValidators.required(v, this.$t('views.contact.form.fieldMsg') as string)
  ];
  private ruleAgreement = [
    (v: any) =>
      CoreInputValidators.required(v, this.$t('views.contact.form.fieldAgreement') as string)
  ];

  private async checkForm(): Promise<void> {
    if (this.form.validate()) {
      try {
        const response = await api.post('/utils/emails/site/contact', {
          'topic': this.topic,
          'email': this.email,
          'msg': this.msg
        });
        if (response.status === 200) {
          this.form.reset();
          this.showSnackbar({
            message: this.$t('views.contact.form.msgSent') as string,
            type: PwrSnackbarTypes.SUCCESS
          });
        } else {
          this.showErrorSnackbar(this.$t('views.contact.form.somethingWrong') as string);
        }
      } catch (e) {
        this.showErrorSnackbar(this.$t('views.contact.form.somethingWrong') as string);
      }
    }
  }
}
